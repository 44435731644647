import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedId } from "../../store/reducers/modelChoiceSlice";
import { setDimension } from "../../store/reducers/dimensionsSlice";
import { selectShedOptionsState } from "../../store/reducers/shedOptionsSlice";
import SliderContainer from "./SliderComponent/SliderContainer";
import "./LengthAndWidthChoiceComponent.css";

const LengthAndWidthChoiceComponent = () => {
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const shedOptions = useSelector(selectShedOptionsState);

  const dimensions = {
    "1.a": { width: [300, 500], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
    "1.b": { width: [550, 750], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
    "1.c": { width: [1100, 1500], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
    "1.d": { width: [550, 750], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
    "1.e": { width: [550, 750], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
    "1.f": { width: [550, 750], length: [400, 900], height: [200, 360], roofDimension: [0, 50] },
  };

  const defaultValues = { width: [170, 222], length: [170, 222], height: [170, 222], roofDimension: [0, 50] };

  useEffect(() => {
    const initialOrDefaultDimensions = dimensions[selectedId] || defaultValues;
    Object.entries(initialOrDefaultDimensions).forEach(([dimensionType, [initialValue]]) => {
      dispatch(setDimension({ selectedId, dimensionType, value: initialValue }));
    });
  }, [selectedId, dispatch]);

  const handleDimensionChange = (dimensionType, value) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= dimensions[selectedId][dimensionType][0] && numericValue <= dimensions[selectedId][dimensionType][1]) {
      dispatch(setDimension({ selectedId, dimensionType, value: Math.round(numericValue).toString() }));
    } else {
      // handle invalid input case here, e.g., revert to previous valid value or show an error message
    }
  };

  const { width, length, height, roofDimension } = dimensions[selectedId] || defaultValues;

  // Check if specific shed options are selected in the selectedRoomOption
  const is32aSelected = shedOptions.selectedRoomOption === "3.2.a";
  const is32bSelected = shedOptions.selectedRoomOption === "3.2.b";
  const is32cSelected = shedOptions.selectedRoomOption === "3.2.c";

  const isGrilleTypeChecked = shedOptions.isGrilleTypeChecked;

  return (
    <div className="scrollable-dropdown-container mt-3">
      <SliderContainer
        id="slider"
        min={0}
        max={1}
        realMin={width[0]}
        realMax={width[1]}
        dimension="Šířka přístřešku:"
        onDimensionChange={(value) => handleDimensionChange("width", value)}
        selectedId={selectedId}
        customDimensionText={(value) => `Šířka vjezdu: ${value - 20} cm`} 
        pgDimensionInfo={is32aSelected || is32cSelected ? (value) => `S technickou místností: ${value + 300} cm` : null}
      />
      
      <SliderContainer
        id="slider2"
        min={0}
        max={1}
        realMin={length[0]}
        realMax={length[1]}
        dimension="Délka přístřešku:"
        onDimensionChange={(value) => handleDimensionChange("length", value)}
        selectedId={selectedId}
        customDimensionText={(value) => `Délka vjezdu: ${value - 20} cm`} 
        pgDimensionInfo={is32bSelected ? (value) => `S technickou místností: ${value + 300} cm` : null}
      />
      <SliderContainer
        id="slider3"
        min={0}
        max={1}
        realMin={height[0]}
        realMax={height[1]}
        dimension="Výška přístřešku:"
        onDimensionChange={(value) => handleDimensionChange("height", value)}
        selectedId={selectedId}
        customDimensionText={(value) =>
          `Výška vjezdu: ${value - (isGrilleTypeChecked ? 35 : 37)} cm`
        }
      />
      {/*
      <SliderContainer
        id="slider4"
        min={0}
        max={1}
        realMin={roofDimension[0]}
        realMax={roofDimension[1]}
        dimension="Wysunięcie dachu:"
        onDimensionChange={(value) => handleDimensionChange("roofDimension", value)}
        selectedId={selectedId}
        showInfoIcon={true}
      />
      */}
      <div className="information-container">
        <div className="information-header">Standardní rozměry přístřešku:</div>
        <div className="information-text">Jednostání: <strong>300 x 600 x 270 cm</strong> (šíř. x dél. x výš.)</div>
        <div className="information-text">Dvoustání: <strong>600 x 600 x 270 cm</strong> (šíř. x dél. x výš.)</div>
        <div className="information-text">Vícestání: <strong>1100 x 600 x 270 cm</strong> (šíř. x dél. x výš.)</div>
      </div>
    </div>
  );
};

export default LengthAndWidthChoiceComponent;
