// translateShedOptions.js
export const translateIsGarageChecked = (isGarageChecked) => isGarageChecked ? "Ano," : "Ne";

export const translateIsRoomChecked = (isRoomChecked) => isRoomChecked ? "Ano," : "Ne";

export const translateSelectedGarageOption = (selectedGarageOptions) => {
  const hasLeft = selectedGarageOptions.includes('3.1.a');
  const hasRight = selectedGarageOptions.includes('3.1.b');

  if (hasLeft && hasRight) {
    return 'zprava a zleva';
  } else if (hasLeft) {
    return 'zleva';
  } else if (hasRight) {
    return 'zprava';
  } else {
    return ''; 
  }
};

export const translateSelectedRoomOption = (selectedRoomOption) => {
  switch (selectedRoomOption) {
    case '3.2.a': return 'zleva';
    case '3.2.b': return 'zezadu';
    case '3.2.c': return 'zprava';
    default: return '';
  }
};

export const translateIsShedPositionChecked = (isShedPositionChecked) => isShedPositionChecked ? "U zdi" : "Standard";

export const translateShedPosition = (shedPosition) => {
  if (shedPosition.includes('3.3.a')) return 'z pravé strany';
  if (shedPosition.includes('3.3.b')) return 'z levé strany';
  if (shedPosition.includes('3.3.c')) return 'zezadu';
  return '';
};

export const translateIsSnowZoneChecked = (isSnowZoneChecked) => isSnowZoneChecked ? "Ano" : "Chybí";

export const translateIsMontageChecked = (isMontageChecked) => isMontageChecked ? "Ano" : "Chybí";

export const translateisPhotovoltaicsReinforcementChecked = (isPhotovoltaicsReinforcementChecked) => isPhotovoltaicsReinforcementChecked ? "Ano" : "Ne";

export const translateIsGutterTypeChecked = (isGutterTypeChecked) => isGutterTypeChecked ? "Čtvercová" : "Kulatá";

export const translateIsGrillTypeChecked = (isGrilleTypeChecked) => isGrilleTypeChecked ? "Prémiová" : "Standardní";

export const translateIsWallTypeChecked = (isWallTypeChecked, isGarageChecked, isRoomChecked) => {
  if (!isGarageChecked && !isRoomChecked) {
    return "-";
  }
  return isWallTypeChecked ? "typ VLNKA" : "deska PIR";
};
