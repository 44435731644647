const panelIdToName = {
  '5.a': 'Vlnitá',
  '5.b': 'Horizontální',
  '5.c': 'Moderní+',
  '5.d': 'Žaluzie',
};

export const translatePanelId = (panelId) => {
  return panelIdToName[panelId] || panelId;
};
