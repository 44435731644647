import { useSelector } from "react-redux";
import { selectDimensionsBySelectedId } from "../../../store/reducers/dimensionsSlice";
import { selectSelectedId as selectSelectedModelId } from "../../../store/reducers/modelChoiceSlice";
import { selectShedOptionsState } from "../../../store/reducers/shedOptionsSlice";

export const useFormattedDimensions = () => {
  const selectedModelId = useSelector(selectSelectedModelId);
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );
  const shedOptions = useSelector(selectShedOptionsState);
  const selectedRoomOption = shedOptions.selectedRoomOption;

  // Base formatted dimensions
  let formattedLength = parseInt(dimensions.length);
  let formattedWidth = parseInt(dimensions.width);
  let pgDimensionInformation = ''; // Initialize as an empty string

  // Set default formatted dimensions
  let defaultFormattedLength = `${formattedLength} cm`;
  let defaultFormattedWidth = `${formattedWidth} cm`;

  // Determine the value of pgDimensionInformation based on selectedRoomOption
  if (selectedRoomOption === '3.2.b') {
    pgDimensionInformation = "*Délka přístřešku spolu s délkou technické místnosti";
    formattedLength = `${formattedLength + 300} cm* : ${formattedLength} cm + 300 cm`;
    formattedWidth = defaultFormattedWidth;
  } else if (selectedRoomOption === '3.2.a' || selectedRoomOption === '3.2.c') {
    pgDimensionInformation = "*Šířka přístřešku spolu se šířkou technické místnosti";
    formattedWidth = `${formattedWidth + 300} cm* : ${formattedWidth} cm + 300 cm`;
    formattedLength = defaultFormattedLength;
  } else {
    // If no room option is selected, use default formatted dimensions
    formattedLength = defaultFormattedLength;
    formattedWidth = defaultFormattedWidth;
  }

  return {
    formattedWidth,
    formattedLength,
    formattedHeight: parseInt(dimensions.height),
    formattedRoofDimension: parseInt(dimensions.roofDimension),
    pgDimensionInformation, // Return the pgDimensionInformation
  };
};
