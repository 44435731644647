export const infoData = {
  "1.a": {
    text: "Zajistěte svému vozidlu vynikající ochranu s jednostánkovým přístřeškem, navrženým s důrazem na trvanlivost a estetiku. Konstrukce, vyrobená z oceli s žárovým zinkováním a práškovým nátěrem, zaručuje dlouhodobé použití bez potřeby pravidelné údržby. Tento elegantní přístřešek poskytuje komplexní ochranu, účinně chrání váš automobil před škodlivými UV záření, dešťovými srážkami a silnými poryvy větru, udržuje ho v perfektním stavu po celý rok."
    ,
    image: "pojedyncza.jpg",
    title: "Jednostání",
  },
  "1.b": {
    text: "Dvoustanoviště přístřešek je synonymem ochrany a prostoru. Díky robustní konstrukci z nejlepších materiálů poskytuje bezpečný úkryt pro dvě vozidla před nepříznivými povětrnostními podmínkami. Elegantní povrchová úprava a moderní design z něj činí nejen praktický, ale také estetický doplněk jakékoli nemovitosti. Doplňkový prostor může sloužit také jako místo pro ukládání kol, zahradních nářadí nebo sezónního vybavení.",
    image: "podwojna.jpg",
    title: "Dvoustání",
  },
  "1.c": {
    text: "Proměňte své okolí v prostor plný elegance a funkčnosti s moderním vícestanovištěm. Vyrobeno z pečlivě vybraných materiálů, jako je žárově zinkovaná ocel, naše konstrukce jsou synonymem trvanlivosti a minimální údržby. Díky těmto řešením zůstane vaše investice bez koroze a poškození po mnoho let, bez potřeby pravidelných oprav. To je ideální řešení pro každého, kdo si cení kvality a estetiky – od velkých rodin, přes developery, až po správce sídlišť. Investováním do našich přístřešků investujete do své budoucnosti, zvyšujete hodnotu a prestiž vaší nemovitosti a získáváte jistotu, že vaše parkovací místo zůstane bezpečné, stylové a praktické na dlouhou dobu.",
    image: "zbrama.jpg",
    title: "Vícestání",
  },
  "1.d": {
    text: "Poskytněte svému vozidlu a majetku nejvyšší úroveň ochrany tím, že si vyberete přístřešek s garáží. Naše konstrukce zaručují trvanlivost a odolnost proti různým povětrnostním podmínkám. Garáž poskytuje další ochranu, nejen proti dešti a sněhu, ale také proti potenciálním aktům vandalismu. Minimalistický vzhled a moderní dokončení zajišťují, že náš přístřešek s garáží skvěle ladí s jakýmkoli pozemkem, zvyšuje jeho hodnotu a funkčnost."
    ,
    image: "wiatazgaraz.webp",
    title: "Přístřešek s garáží",
  },
  "1.e": {
    text: "Transformujte svůj pozemek tím, že si vyberete přístřešek se skladovým prostorem na straně, který spojuje eleganci a funkčnost v jednom. Vyrobena z vysoce kvalitních materiálů, naše konstrukce zaručuje dlouhou životnost a odolnost vůči povětrnostním podmínkám na mnoho let. Skladovací prostor na straně je skvělým řešením pro ty, kteří potřebují další místo pro uložení nářadí, zahradního vybavení nebo kol, aniž by museli obětovat estetiku okolí.",
    image: "wiatazboku.webp",
    title: "Přístřešek s místností na boku",
  },
  "1.f": {
    text: "Vyberte si přístřešek se skladovacím prostorem vzadu, abyste vytvořili praktické a estetické místo na svém pozemku. Naše konstrukce, vyrobené z nejlepších materiálů, poskytují nejen ochranu vašeho vozidla, ale také dodatečný prostor pro ukládání nářadí, zahradního vybavení nebo jiných nezbytných předmětů. Skladovací prostor umístěný vzadu přístřešku nenarušuje harmonii okolí a moderní design zajišťuje, že celkový vzhled je stylový.",
    image: "wiataztylu.webp",
    title: "Přístřešek s místností vzadu",
  },
  "slider4": {
    text: "Zákruty jsou základem stability našich přístřešků, zajišťují pevnost konstrukce a ochranu proti extrémním povětrnostním podmínkám. Tyto vysoce kvalitní ocelové zpevňující prvky jsou klíčové pro rozložení zatížení, což činí přístřešek odolným proti větru a sněhu, zatímco si zachovává svou elegantní estetiku. Díky zákrutům naše přístřešky zaručují spolehlivost a trvanlivost, nabízejí bezpečné útočiště pro vaše vozidla.",
    image: "zbrama.jpg",
    title: "Přesah střechy",
  },
  "3.4.a": {
    text: "Naše přístřešky jsou navrženy tak, aby poskytovaly maximální ochranu v různých povětrnostních podmínkách, včetně oblastí s rozdílnou sněhovou zátěží. Nabízíme modely přizpůsobené pro 1., 2. a 3. sněhovou zónu, což umožňuje ideální přizpůsobení konstrukce místním podmínkám. Díky použití materiálů vysoké kvality a promyšleným konstrukčním řešením naše přístřešky zaručují trvanlivost a stabilitu i v případě intenzivních sněhových srážek. Ať už potřebujete ochranu v mírném klimatu nebo v oblasti ohrožené těžkými zimními podmínkami, naše přístřešky poskytnou spolehlivou ochranu pro vaše vozidla a vybavení.",
    image: "zbrama.jpg",
    title: "Sněhová oblast",
  },
  "3.8.a": {
    text: "Každá střecha v sestavě obsahuje STANDARDNÍ MASKOVACÍ PROFIL. Objednejte si také profilovaný PREMIUM maskovací profil - moderní a elegantní řešení, které určitě obohatí vzhled Vaší střešní konstrukce! Organický povlak maskovacích profilů je vyroben z termoplastické dvouvrstvé barvy PVDF o tloušťce 35 μm a je polymerizován při vysoké teplotě.",
    image: "mask14.webp",
    title: "Druh krytky Standardní/Prémiová",
  },
  "5.a": {
    text: "Panely vyrobené z vlnité oceli zajišťují úplnou ochranu před nepříznivými povětrnostními podmínkami. Absence průhledů zaručuje větší soukromí a předměty uchovávané uvnitř zůstávají neviditelné."
    ,
    image: "scianka-falista.jpg",
    title: "Vlnitá",
  },
  "5.b": {
    text: "Panel vyrobený na základě ocelového, ohýbaného profilu. Široké lamely (10 cm) zajišťují malé průhledy a vizuální kontakt s okolím.",
    image: "scianka-horyzontal.jpg",
    title: "Horizontální",
  },
  "5.c": {
    text: "Panel vyrobený na základě ocelového, ohýbaného profilu. Vyznačuje se mimořádně širokými lamelami (29 cm).",
    image: "scianka-modern.jpg",
    title: "Moderní+",
  }, 
  "5.d": {
    text: "Moderní žaluziový systém postavený z ohýbaného ocelového profilu. Díky téměř úplnému uzavření a absenci přímých průhledů poskytuje ochranu obsahu uchovávaného uvnitř místnosti.",
    image: "scianka-zaluzja.jpg",
    title: "Žaluzie",
  },
};

