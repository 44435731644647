import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelChoiceRhombus from "./PanelChoiceRhombus";
import "./PanelChoiceComponent.css";
import {
  setSelectedId,
  selectSelectedId,
} from "../../store/reducers/panelChoiceSlice";
import useInfoWindow from "../../hooks/useInfoWindow"; 

const PanelChoiceComponent = () => {
  const images = ["panel1.png", "panel3.png", "panel2.png", "panel4.jpeg"];
  const labels = ["Vlnitá", "Horizontální", "Moderní+", "Žaluzie"];  
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow();

  const handleSelect = (id) => {
    dispatch(setSelectedId(id));
  };

  return (
    <div className="flex flex-wrap panel-choice-container">
      <div className="flex justify-center panel-choice-row">
        <div onClick={() => handleSelect("5.a")}>
          <PanelChoiceRhombus
            id="5.a"
            image={images[0]}
            label={labels[0]}
            selected={selectedId === "5.a"}
            toggleInfoWindow={() => toggleInfoWindow("5.a")}
          />
        </div>
        <div onClick={() => handleSelect("5.b")}>
          <PanelChoiceRhombus
            id="5.b"
            image={images[1]}
            label={labels[1]}
            selected={selectedId === "5.b"}
            toggleInfoWindow={() => toggleInfoWindow("5.b")}
          />
        </div>
        <div onClick={() => handleSelect("5.c")}>
          <PanelChoiceRhombus
            id="5.c"
            image={images[2]}
            label={labels[2]}
            selected={selectedId === "5.c"}
            toggleInfoWindow={() => toggleInfoWindow("5.c")}
          />
        </div>
        <div onClick={() => handleSelect("5.d")}>
          <PanelChoiceRhombus
            id="5.d"
            image={images[3]}
            label={labels[3]}
            selected={selectedId === "5.d"}
            toggleInfoWindow={() => toggleInfoWindow("5.d")}
          />
        </div>
      </div>
      {renderInfoWindow()}
    </div>
  );
};

export default PanelChoiceComponent;
