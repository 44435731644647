import React from 'react';
import './SendPdfButton.css';

const SendPdfButton = ({ onClick, isButtonEnabled, isLoading }) => {
  return (
    <button
      className={`send-pdf-btn ${(!isButtonEnabled || isLoading) ? 'disabled' : ''}`}
      onClick={onClick}
    >
      Poslat 
    </button>
  );
};

export default SendPdfButton;
