import React, { useState, useEffect } from "react";
import ListComponent from "./components/ListComponent/ListComponent";
import ModelChoiceComponent from "./components/ModelChoiceComponents/ModelChoiceComponent";
import ColorChoiceComponent from "./components/ColorChoiceComponent/ColorChoiceComponent";
import PanelChoiceComponent from "./components/PanelChoiceComponent/PanelChoiceComponent";
import PanelColorChoiceGrid from "./components/PanelColorChoiceComponent/PanelColorChoiceGridsContainer";
import ShedOptionsComponent from "./components/ShedOptionsComponent/ShedOptionsComponent";
import InfoIcon from "./components/miscellaneousComponents/InfoIcon/InfoIcon";
import PanelPlacementChoiceComponent from "./components/PanelPlacementChoiceComponent/PanelPlacementChoiceComponent";
import LengthAndWidthChoiceComponent from "./components/LengthAndWidthChoiceComponent/LengthAndWidthChoiceComponent";
import './UiContainer.css';

const UiContainer = ({ setCloseAll }) => {
  const [isOpen, setIsOpen] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
  });

  const closeAllItems = () => {
    const newIsOpen = Object.keys(isOpen).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setIsOpen(newIsOpen);
  };

  useEffect(() => {
    setCloseAll(() => closeAllItems);
  }, [setCloseAll]);

  const toggleOpen = (item) => {
    const newIsOpen = Object.keys(isOpen).reduce((acc, key) => {
      acc[key] = key === item ? !isOpen[key] : false;
      return acc;
    }, {});
    setIsOpen(newIsOpen);
  };
  
  const listItems = [
    { number: "1.", title: "Výběr přístřešku", component: <ModelChoiceComponent /> },
    { number: "2.", title: "Rozměry přístřešku", component: <LengthAndWidthChoiceComponent /> },
    { number: "3.", title: "Možnosti přístřešku", component: <ShedOptionsComponent /> },
    { number: "4.", title: "Barva přístřešku", component: <ColorChoiceComponent /> },
    {
      number: "5.",
      title: "Výběr bočních panelů",
      component: <PanelChoiceComponent />,
    },
    {
      number: "6.",
      title: "Umístění bočních panelů",
      component: <PanelPlacementChoiceComponent />
    },
    {
      number: "7.",
      title: "Barva bočních panelů",
      component: <PanelColorChoiceGrid />,
    },
  ];


  return (
    <div
      className="config-block"
      id="appui"
      style={{
        //display: "none",
        overflowY: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      {listItems.map((item, index) => (
        <div
          key={index}
          className="w-full p-1 px-3 shadow-md relative z-5 mb-1.5 bg-opacity-50"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
        >
          <ListComponent
            number={item.number}
            title={item.title}
            items={[{ id: `item${index + 1}`, component: item.component }]}
            toggleOpen={() => toggleOpen(`item${index + 1}`)}
            isOpen={isOpen[`item${index + 1}`]}
            renderListItem={(item, index) => item.component}
          />
        </div>
      ))}


      <InfoIcon />
    </div>
  );
};

export default UiContainer;
