export const translateColorCode = (colorCode) => {
  const colorMap = {
    // Pole and Facade Colors
    '4.1.a': 'Antracit', '4.3.a': 'Antracit',
    '4.1.b': 'Hnědý', '4.3.b': 'Hnědý',
    '4.1.c': 'Světle hnědý', '4.3.c': 'Světle hnědý',
    '4.1.d': 'Stříbrný', '4.3.d': 'Stříbrný',
    '4.1.e': 'Černý', '4.3.e': 'Černý',
    '4.1.f': 'Bílý', '4.3.f': 'Bílý',
    // Roof Colors
    '4.2.a': 'Antracit',
    '4.2.b': 'Ciemny brąz',
    '4.2.c': 'Stříbrný',
    '4.2.d': 'Černý',
  };

  return colorMap[colorCode] || 'Unknown Color';
};
