export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};


export const validatePostalCode = (postalCode) => {
  const regex = /^[0-9]{3}\s?[0-9]{2}$/;
  return regex.test(postalCode);
};



export const validatePhone = (phone) => {
  const regex = /^(?:\+420)?\s?(?:0)?\s?([0-9]{3})\s?-?([0-9]{3})\s?-?([0-9]{3})$/;
  return regex.test(phone);
};
