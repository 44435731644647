import React, { useState } from 'react';
import './SummaryButton.css';
import SummaryFormComponent from '../SummaryFormComponent/SummaryFormComponent';

const SummaryButton = ({ id = 'default-summary-button-id', onCloseAll }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    if (onCloseAll) {
      onCloseAll();
    }
    setIsPopupOpen(true);
  };

  return (
    <>
      <button id={id} className="summary-button" onClick={handleOpenPopup}>
        <span className="summary-text">Shrnutí</span>
      </button>
      {isPopupOpen && <SummaryFormComponent onClose={() => setIsPopupOpen(false)} />}
    </>
  );
};

export default SummaryButton;

