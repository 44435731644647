const getModelTypeText = (selectedModelId) => {
  switch (selectedModelId) {
    case '1.a':
      return 'Jednostání';
    case '1.b':
      return 'Dvoustání';
    case '1.c':
      return 'Vícestání';
    case '1.d':
      return 'Dvoustání';
    case '1.e':
      return 'Dvoustání';
    case '1.f':
      return 'Dvoustání';
  }
};

export default getModelTypeText;
