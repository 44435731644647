import React from "react";
import ColorChoiceGrid from "./ColorChoiceGrid";
import "./ColorChoiceComponent.css";

const ColorChoiceComponent = () => {
  const row1Ids = ["4.1.a", "4.1.b", "4.1.c", "4.1.d", "4.1.e", "4.1.f"];
  const row2Ids = ["4.2.a", "4.2.b", "4.2.c", "4.2.d"];
  const row3Ids = ["4.3.a", "4.3.b", "4.3.c", "4.3.d", "4.3.e", "4.3.f"];

  const row1Images = [
    "colors/Antracyt.jpg",
    "colors/Braz.jpg",
    "colors/Jasnybraz.jpg",
    "colors/Srebrny.jpg",
    "colors/Czarny.jpg",
    "colors/Bialy.jpg",
  ];

  const row2Images = [
    "colors/Antracyt.jpg",
    "colors/Braz.jpg",
    "colors/Srebrny.jpg",
    "colors/Czarny.jpg",
  ];

  const row3Images = [
    "colors/Antracyt.jpg",
    "colors/Braz.jpg",
    "colors/Jasnybraz.jpg",
    "colors/Srebrny.jpg",
    "colors/Czarny.jpg",
    "colors/Bialy.jpg",
  ];


  const row1Names = ["RAL 7016 (Antracit)", "RAL 8017 (Hnědý)", "RAL 8003 (Světle hnědý)", "RAL 9006 (Stříbrný)", "RAL 9005 (Černý)", "RAL 9003 (Bílý)"];
  const row2Names = ["RAL 7016 (Antracit)", "RAL 8017 (Hnědý)", "RAL 9006 (Stříbrný)", "RAL 9005 (Černý)"];
  const row3Names = ["RAL 7016 (Antracit)", "RAL 8017 (Hnědý)", "RAL 8003 (Světle hnědý)", "RAL 9006 (Stříbrný)", "RAL 9005 (Černý)", "RAL 9003 (Bílý)"];
  return (
    <div className="flex-wrap color-choice-container">
      <h3 className="header-text">Barva sloupů</h3>
      <ColorChoiceGrid ids={row1Ids} images={row1Images} names={row1Names} category="poleColor" />

      <h3 className="header-text">Barva střechy</h3>
      <ColorChoiceGrid ids={row2Ids} images={row2Images} names={row2Names} category="roofColor" />

      <h3 className="header-text">Barva masky</h3>
      <ColorChoiceGrid ids={row3Ids} images={row3Images} names={row3Names} category="facadeColor" />
    </div>
  );
};

export default ColorChoiceComponent;
