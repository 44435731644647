const panelColorIdToName = {
  '7.a': 'Antracit',
  '7.b': 'Hnědý',
  '7.c': 'Světle hnědý',
  '7.d': 'Stříbrný',
  '7.e': 'Černý',
  '7.f': 'Bílý',
};

export const translatePanelColorId = (colorId) => {
  return panelColorIdToName[colorId] || 'Unknown Color';
};
