import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PanelColorChoiceRhombusGrid from "./PanelColorChoiceRhombusGrid";
import { selectSelectedId as selectPanelSelectedId } from "../../store/reducers/panelChoiceSlice";
import { resetPanelColorChoice } from "../../store/reducers/panelColorChoiceSlice";
import "./PanelColorChoiceGridsContainer.css";

const PanelColorChoiceGridsContainer = () => {
  const dispatch = useDispatch();
  const panelSelectedId = useSelector(selectPanelSelectedId);

  useEffect(() => {
    if (panelSelectedId === "5.a") {
      dispatch(resetPanelColorChoice());
    }
  }, [panelSelectedId, dispatch]);

  const ids = ["7.a", "7.b", "7.c", "7.d", "7.e", "7.f"];
  const images = [
    "colors/Antracyt.jpg",
    "colors/Braz.jpg",
    "colors/Jasnybraz.jpg",
    "colors/Srebrny.jpg",
    "colors/Czarny.jpg",
    "colors/Bialy.jpg",
    "colors/Zlotydab.jpg",
    "colors/Ciemnydab.jpg",
  ];

  const colorNames = [
    "RAL 7016 (Antracit)", "RAL 8017 (Hnědý)", "RAL 8003 (Světle hnědý)", "RAL 9006 (Stříbrný)", "RAL 9005 (Černý)", "RAL 9003 (Bílý)",
    "Zloty Dab",
    "Ciemny Dab"
  ];

  return (
    <div className="flex flex-wrap panel-color-choice-container">
      {panelSelectedId === "5.a" && (
        <div className="panel-color-disabled-container">
          <p className="panel-color-disabled-text">
          Vlnitý panel dostupný pouze v antracitové barvě.
          </p>
        </div>
      )}
      <PanelColorChoiceRhombusGrid
        ids={ids}
        images={images}
        colorNames={colorNames}
        panelSelectedId={panelSelectedId}
      />
    </div>
  );
};

export default PanelColorChoiceGridsContainer;
